import React from 'react';

interface Props {
  children: React.ReactNode;
}

class Layout extends React.Component<Props> {
  render() {
    return <div>{this.props.children}</div>;
  }
}

export default Layout;
