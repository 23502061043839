import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './App.scss';
import Layout from './components/Layout';
import Index from './routes/Index';

function App() {
  return (
    <Layout>
      <Router>
        <Switch>
          <Route exact path="/" component={Index} />
        </Switch>
      </Router>
    </Layout>
  );
}

export default App;
